* {
  font-family: 'Manrope', sans-serif !important;
}

body {
  overflow-x: hidden;
}

/* Had to set hide all images because refine keeps adding new images to the page automatically */
body img {
  display: none;
}

.avatar img {
  display: block;
}

a {
  text-decoration: none !important;
}

.my_profile-bg {
  width: 340px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.my_profile_user-img {
  border-radius: 100%;
  margin-left: -64px;
}

.property_details-img {
  width: 100%;
}

.search-input > div > input {
  height: 10px;
  border-radius: 8px;
}

.search-input > div > select {
  border-radius: 8px;
}

@media only screen and (max-width: 450px) {
  .filter {
    min-width: 140px !important;
  }
}

@media only screen and (max-width: 576px) {
  #property-cards {
    grid-template-columns: 1fr;
  }

  #pagination {
    margin-right: auto;
  }
}

@media screen and (max-width: 900px) {
  .my_profile-bg {
      width: 100%;
      border-radius: 15px;
  }

  .my_profile_user-img {
      margin-left: 0px;
      margin-top: -64px;
  }

  .property_details-img {
      width: 100%;
      height: auto;
  }
}